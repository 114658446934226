/* eslint-disable jsx-a11y/anchor-is-valid */
"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";

import { drawMonogram } from "@/utils/design-elements";
import { getPageName, isInCurrentDirectory } from "@/utils/helpers";

interface NavItem {
  label: string;
  href: string;
  side: "left" | "right"; // Indicates which side the item belongs to
  children?: NavItem[]; // Optional children for dropdowns
}

const navItems: NavItem[] = [
  {
    children: [
      { href: "/about/letter-from-our-founder", label: "Letter from Our Founder", side: "left" },
      { href: "/about/responsible-innovation", label: "Responsible Innovation", side: "left" },
      { href: "/about/investment-thesis", label: "Investment Thesis", side: "left" },
      { href: "/about/mission", label: "Mission, Vision, Values", side: "left" },
      { href: "/about/team", label: "Leadership Team, Advisers and Partners", side: "left" },
    ],
    href: "/about",
    label: "About",
    side: "left",
  },
  {
    children: [
      { href: "/happenings/links", label: "LinkBlog", side: "left" },
      { href: "/happenings/news", label: "News", side: "left" },
    ],
    href: "/happenings/",
    label: "Happenings",
    side: "left",
  },
  {
    href: "/foundation",
    label: "Foundation",
    side: "right",
  },
  {
    href: "/join",
    label: "Join",
    side: "right",
  },
];

const renderNavItems = (items: NavItem[], side: "left" | "right"): React.JSX.Element[] => {
  return items
    .filter((item) => item.side === side) // Filter items based on the side
    .map((item) => {
      const [isOpen, setIsOpen] = useState(false);

      const handleMouseEnter = (): void => setIsOpen(true);
      const handleMouseLeave = (): void => setIsOpen(false);

      // Determine if the <ul> should have the "decorate-with-border" class
      const ulClassName =
        item.children && item.children.length >= 3
          ? "top-app-bav-dropdown-nav decorate-with-border"
          : "top-app-bav-dropdown-nav";

      const pathname: string = usePathname() || "";
      const isInDirectory = isInCurrentDirectory(pathname, item.href);

      return (
        <li
          className={getPageName(item.href)}
          key={item.href}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Link
            aria-current={getPageName(pathname) === getPageName(item.href) ? "page" : undefined}
            data-is-in-current-directory={isInDirectory ? "true" : "false"}
            href={item.href}
          >
            {item.label}
          </Link>
          {item.children && (
            <ul className={ulClassName} data-is-open={isOpen ? "true" : "false"}>
              {renderNavItems(item.children, side)}
            </ul>
          )}
        </li>
      );
    });
};

export default function TopAppBar(): React.JSX.Element {
  const pathname: string = usePathname() || "";
  const pageName = getPageName(pathname) === "" ? "home" : getPageName(pathname);
  // console.log(pageName);

  useEffect(() => {
    document.body.setAttribute("data-is-loaded", "true");
  }, []);

  //   useLayoutEffect(() => {
  //     const handleScroll = (): void => {
  //       const threshold = 40; // Adjust the scroll threshold as needed
  //       const isCollapsed = window.scrollY > threshold;
  //       document.body.setAttribute("data-top-app-bar-is-collapsed", String(isCollapsed));
  //     };
  //
  //     // Add the scroll event listener
  //     window.addEventListener("scroll", handleScroll);
  //
  //     // Cleanup the event listener on component unmount
  //     return (): void => {
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }, []);

  return (
    <header data-page-name={pageName}>
      <div className="top-app-bar top-app-bar-with-decoration">
        <div className="top-app-bar-cell top-app-bar-utility-row">
          <ul className="top-app-bar-nav utility-links">
            <li className="investors">
              <Link
                className="inline-text-button-link"
                href="/investors"
                aria-current={
                  getPageName(pathname) === getPageName("investors") ? "page" : undefined
                }
              >
                Investors
              </Link>
            </li>
            <li className="donate">
              <a
                aria-current={getPageName(pathname) === getPageName("donate") ? "page" : undefined}
                className="inline-text-button-link"
                data-color-scheme="neon-rose"
                href="https://thefuturefound.org/donate"
                rel="noreferrer"
                target="_blank"
              >
                Donate
              </a>
            </li>
            <li className="philanthropists">
              <Link
                className="inline-text-button-link"
                href="/philanthropists"
                aria-current={
                  getPageName(pathname) === getPageName("philanthropists") ? "page" : undefined
                }
              >
                Philanthropists
              </Link>
            </li>
          </ul>
        </div>
        <div className="top-app-bar-cell top-app-bar-left">
          <ul className="top-app-bar-nav top-app-bar-nav-primary">
            {renderNavItems(navItems, "left")}
          </ul>
        </div>
        <div className="top-app-bar-cell top-app-bar-brandmark top-app-bar-brandmark-with-wordmark-and-descriptor">
          <Link
            aria-current={getPageName(pathname) === getPageName("/") ? "page" : undefined}
            aria-label="Link to homepage."
            className="symbolmark"
            href="/"
          >
            {drawMonogram({
              size: 128,
            })}
          </Link>
        </div>
        <div className="top-app-bar-cell top-app-bar-right">
          <ul className="top-app-bar-nav top-app-bar-nav-primary align-right">
            {renderNavItems(navItems, "right")}
          </ul>
        </div>
      </div>
    </header>
  );
}
