"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";

import { drawMonogram } from "@/utils/design-elements";
import { formatClassName, getPageName, normalizePath } from "@/utils/helpers";
import { drawIcon, socialLinks } from "@/utils/social-buttons";

interface FooterLinkItem {
  label: string;
  link: string;
}

const footerButtonLinks: FooterLinkItem[] = [
  { label: "Join", link: "/join" },
  { label: "Contact", link: "/contact" },
  { label: "Donate", link: "https://thefuturefound.org/donate" },
];

// const footerLegalAndUtilityLinks: FooterLinkItem[] = [
//   { label: "Privacy", link: "/privacy" },
//   { label: "Terms", link: "/terms" },
// ];

export default function BottomAppBar(): React.JSX.Element {
  const pathname = usePathname();
  const pageName = getPageName(pathname) === "" ? "home" : getPageName(pathname);

  return (
    <div className="footer" data-page-name={pageName}>
      <div className="bottom-app-bar">
        <div className="bottom-app-bar-cell bottom-app-bar-cell-nav">
          <ul className="footer-links footer-button-links">
            {footerButtonLinks.map((item, index) => (
              <li key={index}>
                <Link
                  className={`footer-button-link ${formatClassName(item.label)}`}
                  href={item.link}
                  aria-current={
                    normalizePath(pathname) === normalizePath(item.link) ? "page" : undefined
                  }
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="bottom-app-bar-cell bottom-app-bar-cell-social">
          <ul className="social follow-buttons" data-label="Connect with us on social media">
            {socialLinks.map((link) => (
              <li className={link.group_id} key={link.group_id}>
                <a
                  aria-label={`Follow us on ${link.displayName}`}
                  className="gtm-join-group"
                  data-gtm-param-group-id={link.group_id}
                  href={link.href}
                  rel="nofollow noreferrer"
                  role="button"
                  target="_blank"
                  title={link.displayName}
                >
                  {drawIcon(link.group_id)}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="bottom-app-bar-cell bottom-app-bar-cell-fine-print">
          <div className="copyright-sign-off-device">
            <div className="copyright-sign-off-device-cell copyright-sign-off-device-cell-fine-print">
              The Future Found Inc. is licensed under a{" "}
              <a
                href="https://creativecommons.org/licenses/by-nc-nd/4.0/?ref=chooser-v1"
                rel="noreferrer"
                target="_blank"
              >
                Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License
              </a>
              .{" "}
              <Link
                className="footer-simple-text-link"
                href="/privacy"
                aria-current={
                  normalizePath(pathname) === normalizePath("privacy") ? "page" : undefined
                }
              >
                Privacy Policy
              </Link>
              <dl className="organization-registration-details" style={{ display: "none" }}>
                <dt>The Future Found Registration</dt>
                <dd>
                  In aid of GivingWorks, Charity Number{" "}
                  {process.env.NEXT_PUBLIC_ORGANIZATION_CHARITY_NUMBER || "1078770"}.{" "}
                  <Link
                    className="footer-simple-text-link"
                    href="/privacy"
                    aria-current={
                      normalizePath(pathname) === normalizePath("privacy") ? "page" : undefined
                    }
                  >
                    Privacy Policy
                  </Link>
                </dd>
              </dl>
              {/* <ul className="footer-links">
                {footerLegalAndUtilityLinks.map((item, index) => (
                  <li key={index}>
                    <Link
                      className={`footer-simple-text-link ${formatClassName(item.link)}`}
                      href={item.link}
                      aria-current={
                        normalizePath(pathname) === normalizePath(item.link) ? "page" : undefined
                      }
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul> */}
            </div>
            <div className="copyright-sign-off-device-cell copyright-sign-off-device-cell-monogram">
              <Link
                aria-current={normalizePath(pathname) === normalizePath("/") ? "page" : undefined}
                aria-label="Link to the home page"
                href="/"
              >
                {drawMonogram({
                  size: 512,
                })}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
